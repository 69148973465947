import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthenticationGuard} from './guards/AuthenticationGuard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'splashscreen',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'otp-verification',
    loadChildren: () => import('./pages/otp-verification/otp-verification.module').then(m => m.OtpVerificationPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'employee',
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
      },
    ]
  },
  {
    path: 'alert',
    loadChildren: () => import('./modal/alert/alert.module').then( m => m.AlertPageModule)
  },
  {
    path: 'logout-alert',
    loadChildren: () => import('./modal/logout-alert/logout-alert.module').then( m => m.LogoutAlertPageModule)
  },
  {
    path: 'error-alert',
    loadChildren: () => import('./modal/error-alert/error-alert.module').then( m => m.ErrorAlertPageModule)
  },
  {
    path: 'hospital-list-confirm',
    loadChildren: () => import('./modal/hospital-list-confirm/hospital-list-confirm.module').then( m => m.HospitalListConfirmPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'splashscreen',
    loadChildren: () => import('./pages/splashscreen/splashscreen.module').then( m => m.SplashscreenPageModule)
  },
  // {
  //   path: 'enrollment/?id',
  //   loadChildren: () => import('./pages/enrollment/enrollment.module').then( m => m.EnrollmentPageModule)
  // },
  {
    path: 'enrollment',
    loadChildren: () => import('./pages/enrollment/enrollment.module').then( m => m.EnrollmentPageModule)
  },
  {
    path: 'insured-edit',
    loadChildren: () => import('./pages/insured-edit/insured-edit.module').then( m => m.InsuredEditPageModule)
  },
  {
    path: 'members-edit',
    loadChildren: () => import('./pages/members-edit/members-edit.module').then( m => m.MembersEditPageModule)
  },
  {
    path: 'enrollment-confirm',
    loadChildren: () => import('./modal/enrollment-confirm/enrollment-confirm.module').then( m => m.EnrollmentConfirmPageModule)
  },
  {
    path: 'health-card-confirm',
    loadChildren: () => import('./modal/health-card-confirm/health-card-confirm.module').then( m => m.HealthCardConfirmPageModule)
  },
  {
    path: 'enrollment-delete',
    loadChildren: () => import('./modal/enrollment-delete/enrollment-delete.module').then( m => m.EnrollmentDeletePageModule)
  },
  {
    path: 'multiple-policy',
    loadChildren: () => import('./pages/multiple-policy/multiple-policy.module').then( m => m.MultiplePolicyPageModule)
  },
  {
    path: 'enrollment-check',
    loadChildren: () => import('./modal/enrollment-check/enrollment-check.module').then( m => m.EnrollmentCheckPageModule)
  },
  {
    path: 'policy-landing',
    loadChildren: () => import('./pages/policy-landing/policy-landing.module').then( m => m.PolicyLandingPageModule)
  },
  {
    path: 'logout-user',
    loadChildren: () => import('./modal/logout-user/logout-user.module').then( m => m.LogoutUserPageModule)
  },
  {
    path: 'employee-update',
    loadChildren: () => import('./pages/employee/employee.module').then( m => m.EmployeePageModule)
  },
  {
    path: 'nominee',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/nominee/nominee.module').then( m => m.NomineePageModule)
      },
      {
        path: 'nominee-details',
        loadChildren: () => import('./pages/nominee-details/nominee-details.module').then( m => m.NomineeDetailsPageModule)
      },
    ]
  },
  // {
  //   path: 'nominee-details',
  //   loadChildren: () => import('./pages/nominee-details/nominee-details.module').then( m => m.NomineeDetailsPageModule)
  // },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'info-alert',
    loadChildren: () => import('./modal/info-alert/info-alert.module').then( m => m.InfoAlertPageModule)
  },
  // {
  //   path: 'notification',
  //   loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  // },
  // {
  //   path: 'notification-details',
  //   loadChildren: () => import('./pages/notification-details/notification-details.module').then( m => m.NotificationDetailsPageModule)
  // },


  // {
  //   path: 'gpa-overview',
  //   loadChildren: () => import('./pages/gpa-overview/gpa-overview.module').then( m => m.GpaOverviewPageModule)
  // },
  // {
  //   path: 'gtl-overview',
  //   loadChildren: () => import('./pages/gtl-overview/gtl-overview.module').then( m => m.GtlOverviewPageModule)
  // },
];

@NgModule({
  // imports: [
  //   RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  // ],
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
