import { Injectable } from '@angular/core';
import {NativeStorage} from '@ionic-native/native-storage/ngx';
import {map} from 'rxjs/operators';
import {ApiServiceService} from './api-service.service';
import {Storage} from '@ionic/storage';
import {HttpHeaders, HttpRequest} from '@angular/common/http';

class RequestOptions {
  constructor(param: { headers: Headers }) {
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  token;

  constructor(protected storage: Storage,
              protected api: ApiServiceService) { }

  /**
   * check user exists or not
   */
  async userExists() {
    return await this.storage.get('user');
  }

  /**
   * get access token
   */
  async getToken() {
    return await this.storage.get('access_token');
  }

  /**
   * check user exist or not
   * @param credential
   */
  userCheck(credential) {
    return this.api.post('user-check', credential)
        .pipe(
            map((d: any) => d.data)
        );
  }

  /**
   * After user check generate verification code
   * @param data
   */
  otpGenerate(data) {
    return this.api.post('otp-generate', data)
        .pipe(
            map((d: any) => d.data)
        );
  }

  /**
   * verify verification code and get user details
   * @param data
   */
  otpSubmit(data) {
    return this.api.post('login', data)
        .pipe(
            map((d: any) => d)
        );
  }
  employeeEnrollment(employeeId) {
    return this.api.post('get-employee-enrollments' , {employee_id: employeeId})
            .pipe(
                map((d: any) => d.data)
            );
  }
  /**
   * After user check generate verification code
   * @param data
   */
  resendOTP(data) {
    return this.api.post('resend-otp', data)
        .pipe(
            map((d: any) => d.data)
        );
  }

  /**
   * logout
   */
  logout(token) {
    return this.api.post('logout', {})
        .pipe(
            map((d: any) => d.data)
        );
  }

  /**
   * ContactUs
   */
  contactUs(contactObj) {
    return this.api.post('contact-us', contactObj)
        .pipe(
            map((d: any) => d.data)
        );
  }
}
