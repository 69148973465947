import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {DatePipe} from '@angular/common';
import {DatePickerModule} from 'ionic4-date-picker';
import {httpInterceptorProviders} from './http-interceptor';
import { IonicStorageModule } from '@ionic/storage';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {FileOpener } from '@ionic-native/file-opener/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {DocumentViewer} from '@ionic-native/document-viewer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { IonicSelectableModule } from 'ionic-selectable';
import { Push } from '@ionic-native/push/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Network } from '@ionic-native/network/ngx';
import {ReplaceCompanyService} from './services/replace-company.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    // LogoutAlertPageModule,
      HttpClientModule,
    DatePickerModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(), AppRoutingModule,
    FontAwesomeModule,
    IonicSelectableModule],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    NativeStorage,
    FileOpener,
    DocumentViewer,
    FileTransfer,
    File,
    Push,
    SocialSharing,
    Network,
    ReplaceCompanyService,
    InAppBrowser,
    httpInterceptorProviders,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
