import {ElementRef, Injectable} from '@angular/core';
import {StatusBar} from '@ionic-native/status-bar/ngx';

@Injectable({
  providedIn: 'root'
})
export class ReplaceCompanyService {
  logo;
  primaryColor;
  lightColor;
  secondaryColor;
  companyName;
  tittleName;

  constructor() {
    this.setThemeColor();
  }
  getLogo() {
    return this.logo;
  }
  getCompanyName() {
    return this.companyName;
  }
  getTitleName() {
    return this.tittleName;
  }
  /**
   * setting default theme
   */
  protected setThemeColor() {
    if (window.location.href.split('/')[2] === 'welfare.securus.in' ||
        window.location.href.split('/')[2] === 'staging-welfare.securus.in') {
      this.primaryColor = '#0ba0e2';
      this.lightColor = '#d9f1fb';
      this.secondaryColor = 'rgb(218, 248, 241)';
      this.logo = 'assets/img/securusNewLogo.png';
      this.companyName = 'Securus';
      this.tittleName = 'SIBRO BUDDY';
    } else if (window.location.href.split('/')[2] === 'staging-customerservice.orangecapital.in' ||
               window.location.href.split('/')[2] === 'customerservice.orangecapital.in') {
      this.primaryColor = '#ff8f01';
      this.lightColor = '#fdedde';
      this.secondaryColor = '#f1ca9b';
      this.logo = 'assets/img/Orange-capital-logo.jpg';
      this.companyName = 'Orange Capital';
      this.tittleName = 'Orange Capital';
    } else {
      this.primaryColor = '#0ba0e2';
      this.lightColor = '#d9f1fb';
      this.secondaryColor = 'rgb(218, 248, 241)';
      this.logo = 'assets/img/securusNewLogo.png';
      this.companyName = 'Securus';
      this.tittleName = 'SIBRO BUDDY';
    }
  }
}
