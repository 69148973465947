import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {ReplaceCompanyService} from '../../services/replace-company.service';

@Component({
  selector: 'app-hospital-list-confirm',
  templateUrl: './hospital-list-confirm.page.html',
  styleUrls: ['./hospital-list-confirm.page.scss'],
})
export class HospitalListConfirmPage implements OnInit {
  from: any;
  insuranceCompanyName: any = '';
  claimProcessor: any = '';
  companyName = '';

  constructor(protected modalCtrl: ModalController,
              protected params: NavParams,
              protected storage: Storage,
              protected replaceCompany: ReplaceCompanyService,
              protected router: Router) { }

  ngOnInit() {
    this.from = this.params.get('from');
    this.companyName = this.replaceCompany.getCompanyName();
    Promise.all([this.storage.get('insurance_company_name'), this.storage.get('claim_processor')]).then(values => {
            this.insuranceCompanyName = values[0];
            this.claimProcessor = values[1];
        });
  }
  /**
   *  hospital list confirm
   */
  async done(){
    await this.modalCtrl.dismiss();
    await this.router.navigateByUrl('employee/tabs/overview/hospital-list');
  }

}
