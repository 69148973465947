import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {ApiServiceService} from './api-service.service';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { FileOpenerService } from './file-opener.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  protected baseUrl: string;
  constructor(protected api: ApiServiceService, protected fileOpener: FileOpenerService) {
    this.baseUrl = environment.apiBaseUrl;
   }

    /**
     * get policy data
     * @param policyId
     */
  getPolicyData(policyId) {
    return this.api.get('policy', {policy_id: policyId})
        .pipe(
            map((d: any) => d.data)
        );
  }

    /**
     * get faq details
     * @param policyId
     * @param search
     * @param page
     */
  getFaqDetails(policyId, policyType, search, page) {
    return this.api.get('faq', {policy_id: policyId, policy_type: policyType, search, page})
        .pipe(
            map((d: any) => d.data)
        );
  }

    /**
     * get communication details
     * @param policyId
     * @param search
     * @param page
     */
  getCommunicationDetails(policyId, policyType, search, page) {
    return this.api.get('communications', {policy_id: policyId, policy_type: policyType, search, page})
        .pipe(
            map((d: any) => d.data)
        );
  }

    /**
     * get claims data
     * @param policyId
     * @param page
     * @param policyType
     * @param employeeId
     */
  // getClaimsData(policyId, policyType, tpaId, page, employeeId) {
  getClaimsData(policyId, policyType, page, employeeId) {
      // return this.api.post('get-tpa-claim-list', {policy_id: policyId, policy_type: policyType, tpa_ids: tpaId, employee_id: employeeId, page})
      return this.api.post('get-tpa-claim-list', {policy_id: policyId, policy_type: policyType, employee_id: employeeId, page})
        .pipe(
            map((d: any) => d)
        );
  }

    /**
     * get hospital list
     * @param state
     * @param city
     * @param search
     * @param policyId
     * @param page
     * @param claimProcessorId
     */
  getHospitalList(state, city, search, policyId, page, claimProcessorId) {
        return this.api.post('get-tpa-hospital-list', {state_name: state, city_names: city, search, policy_id: policyId, page,
            claim_processor_id: claimProcessorId})
            .pipe(
                map((d: any) => d.data)
            );
  }

    /**
     * get claims details
     * @param id
     * @param policyId
     */
  getClaimsDetails(id, policyType, policyId) {
    return this.api.get('tpa-claim-list', {policy_id: policyId, policy_type: policyType, claim_id: id})
        .pipe(
            map((d: any) => d.data)
        );
  }

    /**
     * get coverage details
     * @param policyId
     * @param search
     * @param page
     */
  getCoverageDetails(policyId, policyType, search, page) {
        return this.api.get('coverage' , {policy_id: policyId, policy_type: policyType, search, page})
            .pipe(
                map((d: any) => d.data)
            );
  }

    /**
     * get health card
     * @param policyId
     * @param employeeId
     */
  getHealthCard(policyId, policyType, employeeId) {
        return this.api.get('tpa-health-card' , {policy_id: policyId, policy_type: policyType, employee_id: employeeId})
            .pipe(
                map((d: any) => d.data)
            );
  }
  
   /**
   * Get nominee policies
   * @param employeeId
   */
  getNomineePolicy(employeeId, clientId) {
      return this.api.get('get-employee-nominee-policies' , {employee_id: employeeId, client_id: clientId})
          .pipe(
              map((d: any) => d.data)
          );
  }

/**
 * Get nominees
 * @param clientId
 * @param prospectId
 * @param employeeId
 */
  getNomineeDetails(clientId, prospectId, employeeId) {
    return this.api.get('get-nominee-details' , {client_id: clientId, prospect_id: prospectId, employee_id: employeeId})
        .pipe(
            map((d: any) => d.data)
        );
  }

/**
 * Create nominee
 * @param nomineeObject
 */
   addNomineeDetails(nomineeObj) {
    return this.api.post('nominee-details' , nomineeObj)
        .pipe(
            map((d: any) => d.data)
        );
  }

/**
 * Update nominee
 * @param nomineeObject
 */
 updateNomineeDetails(nomineeObj) {
  return this.api.put('nominee-details/' + nomineeObj.id , nomineeObj)
      .pipe(
          map((d: any) => d.data)
      );
  }

/**
 * Delete nominee
 * @param nomineeObject
 */
 deleteNomineeDetails(nomineeObj) {
  return this.api.delete('nominee-details/' + nomineeObj.id)
      .pipe(
          map((d: any) => d.data)
      );
  }

/**
 * Create appointee
 * @param apponiteeObject
 */
   addAppointeeDetails(appointeeObj) {
    return this.api.post('appointee-details' , appointeeObj)
        .pipe(
            map((d: any) => d.data)
        );
  }

/**
 * Update appointee
 * @param appointeeObject
 */
 updateAppointeeDetails(appointeeObj) {
  return this.api.put('appointee-details/' + appointeeObj.id , appointeeObj)
      .pipe(
          map((d: any) => d.data)
      );
  }

/**
 * Delete appointee
 * @param appointeeObject
 */
 deleteApponiteeDetails(appointeeObj) {
  return this.api.delete('appointee-details/' + appointeeObj.id)
      .pipe(
          map((d: any) => d.data)
      );
  }

/**
 * Download nominee declaration form
 * @param employeeId
 */
   getNomineeDeclaration(employeeId, clientId) {
      window.open(this.baseUrl + 'get-employee-nominee-declaration?employee_id=' + employeeId + '&client_id=' + clientId);
      // this.fileOpener.openAttachmentInBrowser('declarationForm.pdf', 'application/pdf', this.baseUrl + 'get-employee-nominee-declaration?employee_id=' + employeeId);
      //this.baseUrl + 'get-employee-nominee-declaration?employee_id' + employeeId;
      //return this.api.get('get-employee-nominee-declaration' , {employee_id: employeeId}, {responseType: 'blob'});
          // .pipe(
          //     map((d: any) => d)
          // );
    }
    getNomineeDeclarationByPost(employeeId, clientId) {
      //window.open(this.baseUrl + 'get-employee-nominee-declaration?employee_id=' + employeeId + '&client_id=' + clientId);
      // this.fileOpener.openAttachmentInBrowser('declarationForm.pdf', 'application/pdf', this.baseUrl + 'get-employee-nominee-declaration?employee_id=' + employeeId);
      //this.baseUrl + 'get-employee-nominee-declaration?employee_id' + employeeId;
      return this.api.post('get-employee-nominee-declaration' , {employee_id: employeeId, client_id: clientId}, {responseType: 'blob'})
          .pipe(
              map((d: any) => d)
          );
    }
    uploadSignAndCreateNomineeDeclaration(employeeId, clientId, file) {
      return this.api.post('get-employee-nominee-declaration' , {employee_id: employeeId, client_id: clientId, file: file})
          .pipe(
              map((d: any) => d)
          );
    }

/**
 * Create appointee
 * @param apponiteeObject
 */
  uploadNomineeDeclaration(declarationObj) {
    return this.api.post('update-employee-nominee-declaration' , declarationObj)
        .pipe(
            map((d: any) => d.data)
        );
  }
}
