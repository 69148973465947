import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {ApiServiceService} from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(protected api: ApiServiceService) { }

  /**
   * get employee details bu policy id
   * @param policyId
   */
  getEmployeeData(policyId, userName ) {
    return this.api.get('employee', {policy_id: policyId, employee_id: userName})
        .pipe(
            map((d: any) => d.data)
        );
  }
  /**
   * get employee details bu policy id
   * @param policyId
   * @param employeeId
   * @param policyType
   */
  getTpaMemberList(policyId, policyType, employeeId) {
    return this.api.get('tpa-member-list', {policy_id: policyId, policy_type: policyType, employee_id: employeeId})
        .pipe(
            map((d: any) => d.data)
        );
  }
  /**
   * get support details
   * @param policyId
   * @param clientId
   * @param page
   * @param search
   */
  getsupportDetails(policyId, policyType, clientId, page, search) {
    return this.api.get('support', {policy_id: policyId, policy_type: policyType, client_id: clientId, search, page})
        .pipe(
            map((d: any) => d.data)
        );
  }
}
