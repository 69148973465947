import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  /**
   * Field types - Master
   */
   FIELD_TYPE_INPUT = 1;
   FIELD_TYPE_NUMBER = 2;
   FIELD_TYPE_DATE = 3;
   FIELD_TYPE_SELECT = 4;
   FIELD_TYPE_EMAIL = 5;

  /**
   * Field types - Claims
   */
  FIELD_TYPE_CLAIMS_INPUT = 1;
  FIELD_TYPE_CLAIMS_SELECT = 2;
  FIELD_TYPE_CLAIMS_DATE = 3;

  FIELD_TYPE_CLASS_MASTER = 'App\\FieldTypeMaster';
  FIELD_TYPE_CLASS_CLAIM_CUSTOM = 'App\\ClaimCustoms';

  constructor() { }

    /**
     * set gmc side menu based on policy type
     */
    gmcSideMenuData() {
        return[
            {
              name: 'Medi Claim',
              icon: 'medkit-outline',
              item: [
                {
                  name: 'Policy Details',
                  url: '/employee/tabs/overview',
                  icon: 'information-circle',
                    type: 'gmc'
                },
                {
                  name: 'Coverage',
                  url: '/employee/tabs/coverage',
                  icon: 'umbrella-outline',
                    type: 'gmc'
                },
                {
                  name: 'Cashless Card',
                  url: '/employee/tabs/health-card',
                  icon: 'document-text',
                    type: 'gmc'
                },
                {
                  name: 'Claim status',
                  url: '/employee/tabs/claims',
                  icon: 'server-outline',
                    type: 'gmc'
                },
                // {
                //   name: 'Reimbursement Claim',
                //   icon: 'flame',
                //   item: [
                //     {
                //       name: 'Process',
                //       icon: 'list',
                //       url: '/employee/tabs/overview',
                //         type: 'gmc'
                //       // item: [
                //       //   {
                //       //     name: 'item-1-2-1-1',
                //       //     url: '/item1211'
                //       //   },
                //       //   {
                //       //     name: 'item-1-2-1-2',
                //       //     url: '/item1212'
                //       //   }
                //       // ]
                //     },
                //     {
                //       name: 'Document Check List',
                //       icon: 'checkbox',
                //       url: '/employee/tabs/overview',
                //         type: 'gmc'
                //     }, {
                //       name: 'Download Claim Form',
                //       icon: 'document',
                //       url: '/employee/tabs/overview',
                //       // url: '/employee/tabs/health-card',
                //           type: 'gmc'
                //     }
                //     ]
                // },
                {
                  name: 'Network Hospital List',
                  url: '/employee/tabs/overview',
                  icon: 'list-circle',
                    type: 'gmc'
                },
                // {
                //   name: 'Cashless Claim',
                //   icon: 'cash',
                //   item: [
                //     // {
                //     //   name: 'Process',
                //     //   icon: 'list',
                //     //   url: '/employee/tabs/overview',
                //     //     type: 'gmc'
                //     // },
                //     {
                //       name: 'Hospital List',
                //       icon: 'list-circle',
                //       url: '/employee/tabs/overview',
                //           type: 'gmc'
                //     }
                //   ]
                // },
                {
                  name: 'FAQs',
                  url: '/employee/tabs/overview/faq',
                  icon: 'help-buoy-outline',
                    type: 'gmc'
                }
              ]
            }
        ];
    }
    /**
     * set gpa side menu based on policy type
     */
    gpaSideMenuData() {
      return [
          {
            name: 'Personal Accident',
            icon : 'medical-outline',
            item: [
              {
                name: 'Policy Details',
                icon: 'information-circle',
                url: '/employee/tabs/overview',
                  type: 'gpa'
              },
              {
                name: 'Coverage',
                icon: 'umbrella-outline',
                url: '/employee/tabs/coverage',
                  type: 'gpa'
              },
              {
                name: 'Claims',
                icon: 'hourglass-outline',
                url: '/employee/tabs/claims',
                  type: 'gpa'
              },
              {
                name: 'FAQs',
                icon: 'help-buoy-outline',
                url: '/employee/tabs/gpa-overview/faq',
                  type: 'gpa'
              }
            ]
          }
      ];
    }
    /**
     * set gtl side menu based on policy type
     */
  gtlSideMenuData() {
      return [
          {
              name: 'Term Life',
              icon : 'fitness-outline',
              item: [
                  {
                      name: 'Policy Details',
                      icon: 'information-circle',
                      url: '/employee/tabs/overview',
                      type: 'gtl'
                  },
                  {
                      name: 'Coverage',
                      icon: 'umbrella-outline',
                      url: '/employee/tabs/coverage',
                      type: 'gtl'
                  },
                  {
                      name: 'Claims',
                      icon: 'hourglass-outline',
                      url: '/employee/tabs/claims',
                      type: 'gtl'
                  },
                  {
                      name: 'FAQs',
                      icon: 'help-buoy-outline',
                      url: '/employee/tabs/gtl-overview/faq',
                      type: 'gtl'
                  }
              ]
          }
      ];
  }
  basicSideMenu() {
      return [{
          name: 'Logout',
          icon : 'log-out-outline',
          url: ''
      }];
  }
    /**
     * set gmc tab menu based on policy type
     */
  gmcTabData() {
      return [{
          name: 'Overview',
          icon : 'pie-chart-outline',
          tab: 'overview',
          type: 'gmc'
      },
      {
          name: 'Coverage',
          icon : 'umbrella-outline',
          tab: 'coverage',
          type: 'gmc'
      },
      {
          name: 'Claims',
          icon : 'hourglass-outline',
          tab: 'claims',
          type: 'gmc'
      }, {
          name: 'Health Card',
          icon : 'card-outline',
          tab: 'health-card',
              type: 'gmc'
      }];
  }
    /**
     * set gpa tab menu based on policy type
     */
  gpaTabData() {
      return [{
          name: 'Overview',
          icon : 'pie-chart-outline',
          tab: 'overview',
          type: 'gpa'
      },
      {
          name: 'Coverage',
          icon : 'umbrella-outline',
          tab: 'coverage',
          type: 'gpa'
      },
      {
          name: 'Claims',
          icon : 'hourglass-outline',
          tab: 'claims',
          type: 'gpa'
      }];
  }
    /**
     * set gtl tab menu based on policy type
     */
    gtlTabData() {
        return [{
            name: 'Overview',
            icon : 'pie-chart-outline',
            tab: 'overview',
            type: 'gtl'
        },
        {
            name: 'Coverage',
            icon : 'umbrella-outline',
            tab: 'coverage',
            type: 'gtl'
        },
        {
            name: 'Claims',
            icon : 'hourglass-outline',
            tab: 'claims',
            type: 'gtl'
        }];
    }
}
